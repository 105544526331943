<template>
  <section :class="!isEdit && 'mb-5'">
    <div class="plantsForm">
      <div v-show="!isEdit">
        <h5 class="text-center text-md-left">
          {{ $t('herbarium_form_page_title_new_item') }}
        </h5>
        <hr class="my-4" />
      </div>
      <div>
        <b-form @submit.prevent="isEdit ? changeItemToDB() : addItemToDB()">
          <div class="row my-4 flex-column flex-md-row">
            <div class="col-12 col-md-3 text-left  mb-4 mb-md-0">
              <h6>{{ $t('plants_form_page_name_title') }}</h6>
            </div>
            <div class="d-flex flex-column flex-lg-row col-12 col-md-9">
              <div class="col-lg-6">
                <b-form-group
                  id="input-group-ruName"
                  :label="$t('herbarium_form_page_input_ruName_label')"
                  label-for="ruName"
                  class="text-left"
                >
                  <b-form-input
                    id="ruName"
                    v-model.trim="$v.form.ruName.$model"
                    :state="validateState('ruName')"
                    type="text"
                  />
                  <b-form-text
                    v-if="$v.form.ruName.$dirty && !$v.form.ruName.required"
                    text-variant="danger"
                  >
                    {{ $t('herbarium_form_page_input_name_error_empty') }}
                  </b-form-text>
                  <b-form-text
                    v-if="$v.form.ruName.$dirty && !$v.form.ruName.maxLength"
                    text-variant="danger"
                  >
                    {{ $t('herbarium_form_page_input_name_error_length') }}
                    {{ $v.form.ruName.$params.maxLength.max }}
                  </b-form-text>
                  <b-form-text
                    v-if="$v.form.ruName.$dirty && !$v.form.ruName.alphaRU"
                    text-variant="danger"
                  >
                    {{
                      $t('profile_classification_modal_ru_input_valid_message')
                    }}
                  </b-form-text>
                </b-form-group>

                <b-form-group
                  id="input-group-enName"
                  :label="$t('herbarium_form_page_input_enName_label')"
                  label-for="enName"
                  class="text-left"
                >
                  <b-form-input
                    id="enName"
                    v-model.trim="$v.form.enName.$model"
                    :state="validateState('enName')"
                    type="text"
                  />
                  <b-form-text
                    v-if="$v.form.enName.$dirty && !$v.form.enName.required"
                    text-variant="danger"
                  >
                    {{ $t('herbarium_form_page_input_name_error_empty') }}
                  </b-form-text>
                  <b-form-text
                    v-if="$v.form.enName.$dirty && !$v.form.enName.maxLength"
                    text-variant="danger"
                  >
                    {{ $t('herbarium_form_page_input_name_error_length') }}
                    {{ $v.form.enName.$params.maxLength.max }}
                  </b-form-text>
                  <b-form-text
                    v-if="$v.form.enName.$dirty && !$v.form.enName.alphaEN"
                    text-variant="danger"
                  >
                    {{
                      $t('profile_classification_modal_en_input_valid_message')
                    }}
                  </b-form-text>
                </b-form-group>
              </div>
            </div>
          </div>
          <hr />
          <div class="row my-4 flex-column flex-md-row">
            <div class="col-12 col-md-3 text-left  mb-4 mb-md-0">
              <h6>{{ $t('herbarium_form_page_classification_title') }}</h6>
            </div>
            <div class="d-flex flex-column flex-lg-row col-12 col-md-9">
              <div class="col-lg-6">
                <b-form-group
                  id="input-group-family"
                  :label="$t('herbarium_form_page_input_family_label')"
                  label-for="family"
                  class="text-left"
                >
                  <Multiselect
                    id="family"
                    v-model="form.family"
                    label="laName"
                    :placeholder="$t('herbarium_form_page_label_tags')"
                    :select-label="$t('vue_multi_select_enter')"
                    :deselect-label="$t('vue_multi_select_remove')"
                    :selected-label="$t('vue_multi_select_selected_label')"
                    open-direction="bottom"
                    :options="familyData"
                    :searchable="true"
                    :loading="isLoading"
                    :internal-search="false"
                    :clear-on-select="true"
                    :close-on-select="true"
                    :options-limit="300"
                    :limit="3"
                    :max-height="600"
                    :allow-empty="false"
                    :show-no-results="false"
                    :hide-selected="false"
                    @search-change="search"
                  >
                    <template slot="noOptions">
                      <span>{{ $t('vue_multi_select_no_options') }}</span>
                    </template>
                    <template slot="noResult">
                      <span>{{ $t('vue_multi_select_no_result') }}</span>
                    </template>
                  </Multiselect>
                </b-form-group>
                <b-form-group
                  id="input-group-genus"
                  :label="$t('herbarium_form_page_input_genus_label')"
                  label-for="genus"
                  class="text-left"
                >
                  <Multiselect
                    id="genus"
                    v-model="form.genus"
                    track-by="id"
                    :custom-label="multiselectName"
                    :placeholder="$t('herbarium_form_page_label_tags')"
                    :select-label="$t('vue_multi_select_enter')"
                    :deselect-label="$t('vue_multi_select_remove')"
                    :selected-label="$t('vue_multi_select_selected_label')"
                    open-direction="bottom"
                    :options="genuses"
                    :searchable="true"
                    :options-limit="300"
                    :limit="3"
                    :multiple="false"
                    :max-height="600"
                    :allow-empty="false"
                    :disabled="!form.family"
                  >
                    <template slot="noOptions">
                      <span>{{ $t('vue_multi_select_no_options') }}</span>
                    </template>
                    <template slot="noResult">
                      <span>{{ $t('vue_multi_select_no_result') }}</span>
                    </template>
                  </Multiselect>
                </b-form-group>
                <b-form-group
                  id="input-group-species"
                  :label="$t('herbarium_form_page_input_species_label')"
                  label-for="species"
                  class="text-left"
                >
                  <Multiselect
                    id="species"
                    v-model="form.species"
                    label="laName"
                    track-by="code"
                    :placeholder="$t('herbarium_form_page_label_tags')"
                    :select-label="$t('vue_multi_select_enter')"
                    :deselect-label="$t('vue_multi_select_remove')"
                    :selected-label="$t('vue_multi_select_selected_label')"
                    open-direction="bottom"
                    :options="species"
                    :searchable="true"
                    :loading="isLoading"
                    :internal-search="false"
                    :clear-on-select="true"
                    :close-on-select="true"
                    :options-limit="300"
                    :limit="3"
                    :max-height="600"
                    :show-no-results="false"
                    :hide-selected="false"
                    :multiple="false"
                    :disabled="!form.genus"
                    @search-change="searchSpecies"
                  >
                    <template slot="noOptions">
                      <span>{{ $t('vue_multi_select_no_options') }}</span>
                    </template>
                    <template slot="noResult">
                      <span>{{ $t('vue_multi_select_no_result') }}</span>
                    </template>
                  </Multiselect>
                </b-form-group>
                <b-form-group
                  id="input-group-sub-species"
                  :label="$t('herbarium_form_page_input_sub_species_label')"
                  label-for="sub-species"
                  class="text-left"
                >
                  <b-form-input
                    id="sub-species"
                    v-model.trim="$v.form.subSpecies.$model"
                    :state="validateState('subSpecies')"
                    type="text"
                  />
                  <b-form-text
                    v-if="
                      $v.form.subSpecies.$dirty && !$v.form.subSpecies.maxLength
                    "
                    text-variant="danger"
                  >
                    {{ $t('herbarium_form_page_input_name_error_length') }}
                    {{ $v.form.subSpecies.$params.maxLength.max }}
                  </b-form-text>
                  <b-form-text
                    v-if="
                      $v.form.subSpecies.$dirty && !$v.form.subSpecies.alphaRUEN
                    "
                    text-variant="danger"
                  >
                    {{ $t('herbarium_form_page_input_error_alpha') }}
                  </b-form-text>
                </b-form-group>
                <b-form-group
                  id="input-group-who-collected"
                  :label="$t('herbarium_form_page_input_genus_holotype')"
                  label-for="holotypeId"
                  class="text-left"
                >
                  <b-form-input
                    id="holotypeId"
                    v-model.trim="$v.form.holotypeId.$model"
                    :state="validateState('holotypeId')"
                    type="text"
                  />
                  <b-form-text
                    v-if="
                      $v.form.holotypeId.$dirty && !$v.form.holotypeId.maxLength
                    "
                    text-variant="danger"
                  >
                    {{ $t('herbarium_form_page_input_name_error_length') }}
                    {{ $v.form.holotypeId.$params.maxLength.max }}
                  </b-form-text>
                  <b-form-text
                    v-if="
                      $v.form.holotypeId.$dirty && !$v.form.holotypeId.alphaRUEN
                    "
                    text-variant="danger"
                  >
                    {{ $t('herbarium_form_page_input_error_alpha') }}
                  </b-form-text>
                </b-form-group>
              </div>
            </div>
          </div>
          <hr />
          <div class="row my-4 flex-column flex-md-row">
            <div class="col-12 col-md-3 text-left  mb-4 mb-md-0">
              <h6>{{ $t('herbarium_form_page_data_title') }}</h6>
            </div>
            <div class="d-flex flex-column flex-lg-row col-12 col-md-9">
              <div class="col-lg-6">
                <b-form-group
                  id="input-group-image"
                  :label="$t('herbarium_form_page_input_image_label')"
                  label-for="image"
                  class="text-left"
                >
                  <b-form-file
                    id="image"
                    accept="image/jpg, image/jpeg,image/png"
                    :placeholder="$t('herbarium_form_page_image_placeholder')"
                    :browse-text="$t('herbarium_form_page_file_input_browse')"
                    @change="loadFile($event)"
                  />
                  <b-form-valid-feedback :state="true">
                    *jpg, jpeg, png
                  </b-form-valid-feedback>
                </b-form-group>
                <b-form-group
                  id="input-group-description"
                  :label="$t('herbarium_form_page_input_description_label')"
                  label-for="description"
                  class="text-left"
                >
                  <b-form-textarea
                    id="description"
                    v-model="form.description"
                    rows="3"
                    max-rows="6"
                  />
                </b-form-group>
                <b-form-group
                  id="input-group-synonyms"
                  :label="$t('plant_item_page_synonyms_title')"
                  label-for="synonyms"
                  class="text-left"
                >
                  <b-form-textarea
                    id="synonyms"
                    v-model="form.synonyms"
                    rows="3"
                    max-rows="6"
                  />
                </b-form-group>
                <div class="d-flex justify-content-between align-items-center">
                  <span>{{ $t('herbarium_form_page_redBook') }}</span>
                  <b-form-checkbox
                    v-model="form.redBook"
                    name="check-button"
                    switch
                  />
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div class="text-center text-md-right">
            <b-button v-show="!isEdit" class="warning" @click="showModal">
              {{ $t('herbarium_form_page_btn_cancel') }}
            </b-button>
            <b-button v-show="isEdit" class="warning" @click="showModal">
              {{ $t('herbarium_form_page_btn_delete') }}
            </b-button>
            <b-button
              type="submit"
              :disabled="isLoading"
              class="mr-auto success"
            >
              <b-spinner v-if="isLoading" show small type="grow" />
              {{
                isEdit
                  ? $t('herbarium_form_page_btn_edit')
                  : $t('herbarium_form_page_btn_submit')
              }}
            </b-button>
          </div>
        </b-form>
      </div>
    </div>
    <Modal
      :confirmation="
        isEdit
          ? () => {
              this.pageLeaveConfirm = true
              deleteItem(id)
            }
          : cancel
      "
      :title="
        isEdit
          ? $t('component_modal_herbarium_delete')
          : $t('component_modal_herbarium_cancel')
      "
      :id-modal="'modalCancel'"
    />
    <Modal
      :confirmation="this.handleModalConfirm"
      :title="$t('component_modal_herbarium_confirm')"
      :id-modal="'modalConfirm'"
    />
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Multiselect from 'vue-multiselect'
import { required, maxLength, helpers } from 'vuelidate/lib/validators'
import Modal from '@/components/Modal.vue'
const alphaRU = helpers.regex('alpha', /^[а-яА-ЯёЁ,-. ]*$/)
const alphaEN = helpers.regex('alpha', /^[a-zA-Z,-. ]*$/)
const alphaRUEN = helpers.regex('alpha', /^[a-zA-Zа-яА-Я,-. ]*$/)
let timer = null

export default {
  components: {
    Modal,
    Multiselect
  },
  props: {
    isEdit: {
      type: Boolean
    },
    id: {
      type: Number,
      default: () => {}
    }
  },
  data() {
    return {
      classificationData: [],
      // species: [],
      // initializing: true,
      form: {
        ruName: '',
        enName: '',
        family: '',
        genus: '',
        species: '',
        subSpecies: '',
        holotypeId: '',
        synonyms: '',
        image: {},
        redBook: false
      },
      pageLeaveConfirm: false,
      pageToGoTo: ''
    }
  },
  validations: {
    form: {
      ruName: { required, alphaRU, maxLength: maxLength(50) },
      enName: { required, alphaEN, maxLength: maxLength(50) },
      holotypeId: { alphaRUEN, maxLength: maxLength(50) },
      subSpecies: { alphaRUEN, maxLength: maxLength(50) }
    }
  },
  computed: {
    ...mapGetters('plants', [
      'getItem',
      'isLoading',
      'getErrorPlants',
      'getFamily',
      'getData',
      'getFilteredData'
    ]),
    ...mapGetters(['languageTake']),
    ...mapGetters('classification', ['classification', 'genusOfFamily']),

    familyData() {
      return this.classification.map(el => {
        return {
          id: el.id,
          name: el.enName,
          laName: el.laName,
          children: el.children
        }
      })
    },
    genuses() {
      return this.genusOfFamily.map(el => {
        return { id: el.id, laName: el.laName, name: el.enName }
      })
    },
    species() {
      return this.getFilteredData.map(el => {
        return {
          id: el.id,
          laName: el.laName,
          category: el.category,
          holotypeId: el.holotypeId,
          subSpecies: el.subSpecies
        }
      })
    },
    isFormEmpty() {
      let form = Object.entries(this.form)
      let result = form.every(elem => {
        if (elem[0] === 'image') {
          return true
        }
        if (elem[1] == false) {
          return true
        }
      })
      return result
    }
  },
  watch: {
    'form.family': function(newVal) {
      if (!this.isEdit && this.form.genus && this.form.genus.id) this.genus = ''

      if (this.form.family && this.form.family.id) {
        this.getGenusOf(newVal)
      }
    },
    'form.genus': function(newVal) {
      if (!this.isEdit && this.form.species && this.form.species.id)
        this.form.species = ''

      if (this.form.genus && this.form.genus.id) {
        this.getSpecies(newVal)
      }
    }
  },
  async beforeRouteLeave(to, from, next) {
    // eslint-disable-next-line
    if(this.pageLeaveConfirm) {
      next()
      return
    }
    if (this.isFormEmpty) {
      next()
      return
    }
    this.pageToGoTo = to
    await this.$root.$emit('bv::show::modal', 'modalConfirm')
    if (this.pageLeaveConfirm) {
      next()
    }
    next(false)
  },
  async mounted() {
    await this.getClassification(`/classification`)
    this.clearErrorAction()
    if (this.isEdit) {
      await this.fetchItem(this.id)
      this.form = {
        ...this.getItem,
        family: this.getItem.category.category
          ? {
              id: this.getItem.category.category.id,
              name: this.getItem.category.category.enName,
              laName: this.getItem.category.category.laName
            }
          : '',
        genus: this.getItem.category
          ? {
              id: this.getItem.category.id,
              name: this.getItem.category.enName,
              laName: this.getItem.category.laName
            }
          : '',
        species: this.getItem
          ? {
              id: this.getItem.id,
              laName: this.getItem.laName,
              category: this.getItem.category,
              holotypeId: this.getItem.holotypeId,
              subSpecies: this.getItem.subSpecies
            }
          : '',
        holotypeId: this.getItem.holotypeId ? this.getItem.holotypeId : '',
        synonyms: this.getItem.synonyms ? this.getItem.synonyms : ''
      }
    }
  },
  methods: {
    ...mapActions('plants', [
      'addItemToDB',
      'fetchItem',
      'changeItemToDB',
      'clearErrorAction',
      'classificationSearch',
      'fetchData',
      'fetchDataFilter'
    ]),
    ...mapActions('classification', ['getClassification', 'getGenus']),
    showModal() {
      this.$root.$emit('bv::show::modal', 'modalCancel', '#btnShow')
    },
    handleModalConfirm() {
      this.pageLeaveConfirm = true
      this.$router.push({ name: this.pageToGoTo.name }).catch(() => {})
    },
    deleteItem() {
      this.$store.dispatch('plants/deleteItem', this.id).then(() => {
        if (!this.getErrorPlants) {
          this.$root.$emit('bv::hide::modal', 'editModal')
        }
      })
    },
    cancel() {
      this.$router
        .push({
          name: 'userHerbariumsHandbooks'
        })
        .catch(() => {})
    },
    changeItemToDB() {
      this.pageLeaveConfirm = true
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      const formData = new FormData()
      this.form.categoryId = this.form.genus.id
      this.form.laName = this.form.species.laName
      delete this.form.genus
      delete this.form.family
      delete this.form.species
      Object.keys(this.form).forEach(key => {
        formData.append(key, this.form[key])
      })
      const dataForGhange = { id: this.id, form: formData }
      this.$store.dispatch('plants/changeItemToDB', dataForGhange).then(() => {
        if (!this.getErrorPlants) {
          this.$root.$emit('bv::hide::modal', 'editModal')
        }
      })
    },
    addItemToDB() {
      this.pageLeaveConfirm = true
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      const formData = new FormData()
      this.form.categoryId = this.form.genus.id
      this.form.laName = this.form.species.laName
        ? this.form.species.laName
        : ''
      delete this.form.genus
      delete this.form.family
      delete this.form.species
      Object.keys(this.form).forEach(key => {
        formData.append(key, this.form[key])
      })
      this.$store.dispatch('plants/addItemToDB', formData).then(() => {
        if (!this.getErrorPlants) {
          this.$router.push('/main/handbooks/plants').catch(() => {})
        }
      })
    },
    loadFile(event) {
      this.form.image = event.target.files[0]
    },
    getSpecies: async function(newVal) {
      await this.fetchDataFilter(`/plants/filter?&genus=${newVal.id}`)
    },
    getGenusOf: async function(newVal) {
      await this.getGenus(`/classification/genus?&family=${newVal.id}`)
    },
    search(item) {
      if (timer) {
        clearTimeout(timer)
      }
      if (item.length >= 1) {
        timer = setTimeout(async () => {
          await this.getClassification(`/classification?&search=${item}`)
        }, 1000)
      }
      // if (timer) {
      //   clearTimeout(timer)
      // }
      // if (item.length >= 2) {
      //   timer = setTimeout(async () => {
      //     this.classificationData = []
      //     await this.classificationSearch(item)
      //     this.classificationData = this.getFamily.map(el => {
      //       return {
      //         id: el.id,
      //         name: el.enName,
      //         children: el.children
      //       }
      //     })
      //   }, 1000)
      // }
    },
    searchSpecies(item) {
      if (timer) {
        clearTimeout(timer)
      }
      if (item.length >= 1) {
        timer = setTimeout(async () => {
          if (this.genus.id) {
            await this.fetchDataFilter(
              `/plants/filter?&genus=${this.form.genus.id}&search=${item}`
            )
          }
        }, 1000)
      }
    },
    multiselectName({ laName }) {
      return laName
    },
    validateState(name) {
      const { $dirty, $error, $model, $invalid } = this.$v.form[name]
      if ($model === null) return null
      if (!$model.length && !$invalid) return null
      return $dirty ? !$error : null
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/.plantsForm {
  padding: 1.5rem 2rem;
  background: #ffffff;
  box-shadow: 0px 4px 64px rgba(216, 231, 255, 0.15);
  border-radius: 4px;
  .multiselect__single {
    background-color: #f7f7f7 !important;
  }
  .custom-file-label {
    background-color: #f7f7f7 !important;
    border: 1px solid #f7f7f7 !important;
    outline: none !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .valid-feedback {
    color: #a7a6a6;
  }
  .warning {
    background-color: #ffffff;
    color: #4f4f4f;
  }
  h5 {
    font-size: 1.313rem;
  }
  h6 {
    font-size: 1.125rem;
  }
  label {
    margin-bottom: 0;
  }
  .b-form-datepicker {
    background-color: #f7f7f7;
    border: none;
    outline: none;
  }
  .form-button {
    background-color: #42b983;
  }
  .form-button:hover {
    background-color: #2c3e50;
  }
}
</style>
